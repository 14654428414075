import React from 'react'
// Importa o arquivo de estilos para a página
import './style.scss'
// Importa o componente de navegação
import Menu from '../../components/nav/menu'
// Importa imagens e outras dependências necessárias
import img from './../../asset/img/11.png'
import Marquee from "react-fast-marquee" // Biblioteca para animação de texto deslizante
import { HiArrowUpRight } from "react-icons/hi2"; // Ícone usado no projeto
import Footer from '../../components/footer'; // Importa o rodapé da aplicação
import { Projects } from '../../components/projects'

export default function Home() {
  return (
    <main>
      {/* Seção principal da página */}
      <section>
        {/* Componente de menu de navegação */}
        <Menu />

        {/* Jumbotron: Seção introdutória */}
        <div className="jumbotron">
          <div className="container">
            <h1 className="display-3 fw-bold mb-4">
              Desenhando experiências digitais que inspiram e conectam
            </h1>
          </div>

          {/* Informação sobre o desenvolvedor */}
          <div className="container mt-5 pt-5">
            <div className="row align-items-center">
              {/* Imagem do desenvolvedor */}
              <div className="col-lg-4 text-center">
                <div className="avatar shadow-lg rounded-circle overflow-hidden">
                  <img src={img} alt="Avatar" className="img-fluid rounded-circle" />
                </div>
              </div>
              {/* Descrição do desenvolvedor */}
              <div className="col-lg-7">
                <h3 className="fw-light">
                  Sou um desenvolvedor apaixonado por criar soluções digitais que
                  conectam e transformam. Com atenção aos detalhes, entrego projetos
                  que combinam funcionalidade e emoção, simplificando vidas e
                  proporcionando experiências únicas. Acredito no equilíbrio entre
                  tecnologia e humanidade para inspirar e inovar.
                </h3>
              </div>
            </div>
          </div>
        </div>

        {/* Seção com animação de texto deslizante */}
        <div>
          <div className="marquee pb-5 mb-5">
            <Marquee direction='right'>
              {/* Textos que descrevem habilidades e áreas de trabalho */}
              <h1 className='display-3 me-5'><b>• Front-End</b></h1>
              <h1 className='display-3 me-5'><b>• Game Developer</b></h1>
              <h1 className='display-3 me-5'><b>• Design Gráfico</b></h1>
              <h1 className='display-3 me-5'><b>• React</b></h1>
              <h1 className='display-3 me-5'><b>• Angular</b></h1>
              <h1 className='display-3 me-5'><b>• TypeScript</b></h1>
              <h1 className='display-3 me-5'><b>• HTML/CSS</b></h1>
              <h1 className='display-3 me-5'><b>• Sass</b></h1>
              <h1 className='display-3 me-5'><b>• Firebase</b></h1>
              <h1 className='display-3 me-5'><b>• Prototipação</b></h1>
              <h1 className='display-3 me-5'><b>• Soluções Interativas</b></h1>
              <h1 className='display-3 me-5'><b>• Desenvolvimento de Produtos Digitais</b></h1>
              <h1 className='display-3 me-5'><b>• Unity</b></h1>
              <h1 className='display-3 me-5'><b>• C#</b></h1>
              <h1 className='display-3 me-5'><b>• Bootstrap</b></h1>
            </Marquee>
            <Marquee direction='left'>
              {/* Textos que descrevem habilidades e áreas de trabalho */}
              <h1 className='display-3 me-5'><b>• Front-End</b></h1>
              <h1 className='display-3 me-5'><b>• Game Developer</b></h1>
              <h1 className='display-3 me-5'><b>• Design Gráfico</b></h1>
              <h1 className='display-3 me-5'><b>• React</b></h1>
              <h1 className='display-3 me-5'><b>• Angular</b></h1>
              <h1 className='display-3 me-5'><b>• TypeScript</b></h1>
              <h1 className='display-3 me-5'><b>• HTML/CSS</b></h1>
              <h1 className='display-3 me-5'><b>• Sass</b></h1>
              <h1 className='display-3 me-5'><b>• Firebase</b></h1>
              <h1 className='display-3 me-5'><b>• Prototipação</b></h1>
              <h1 className='display-3 me-5'><b>• Soluções Interativas</b></h1>
              <h1 className='display-3 me-5'><b>• Desenvolvimento de Produtos Digitais</b></h1>
              <h1 className='display-3 me-5'><b>• Unity</b></h1>
              <h1 className='display-3 me-5'><b>• C#</b></h1>
              <h1 className='display-3 me-5'><b>• Bootstrap</b></h1>
            </Marquee>
          </div>
        </div>
        {/* Seção de trabalhos em destaque */}
        <Projects visibleCount={4} />
      </section>

      {/* Rodapé da página */}
      <Footer />
    </main>
  )
}
