import React, { useState } from 'react';
import ScrollAnimation from 'react-animate-on-scroll'; // Biblioteca para animações baseadas em rolagem
import img1 from './../../asset/7fbfab4edae308a6ec78df7debe5af1b.png';
import img2 from './../../asset/8a7ac8a932faf143665f03e7739ccf74.png';

// Array de projetos
const projects = [
    { id: 1, title: 'Portal de Empregos', tech: 'Html, CSS', img: img1, align: 'end' },
    { id: 2, title: 'Outro Portal', tech: 'Html, CSS', img: img2, align: 'start' },
    { id: 3, title: 'Projeto 3', tech: 'React, JavaScript', img: img1, align: 'end' },
    { id: 4, title: 'Projeto 4', tech: 'Vue, Tailwind', img: img2, align: 'start' },
  ];

// Definindo o tipo para as props
interface ProjectsProps {
  visibleCount?: number; // Tornando a prop opcional
}

export const Projects: React.FC<ProjectsProps> = ({ visibleCount = projects.length }) => {
  return (
    <div className="container h-100">
      <h1 className="display-3 mb-5">Trabalho em Destaque</h1>
      {projects.slice(0, visibleCount).map((project) => (
        <ScrollAnimation key={project.id} animateIn="fadeIn">
          <div className={`row mb-5 pt-4 pb-4 justify-content-${project.align}`}>
            <div className="col-lg-8">
              {/* Imagem do trabalho */}
              <div className="work-image">
                <img
                  src={project.img}
                  alt={project.title}
                  className="img-fluid rounded shadow"
                />
              </div>
              {/* Título e botão */}
              <div className="mt-4 d-flex justify-content-between align-items-center">
                <h3 className="fw-bold">{project.title}</h3>
                <button
                  type="button"
                  className="btn btn-primary rounded-pill px-4"
                >
                  {project.tech}
                </button>
              </div>
            </div>
          </div>
        </ScrollAnimation>
      ))}
    </div>
  );
};
