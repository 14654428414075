import React from 'react';
import logo from './logo.svg';
import './App.scss';
import Home from './pages/home';
import About from './pages/about';

function App() {
  return <Home/>
}

export default App;
