import React from 'react'
import './index.scss'
export default function Menu() {
  return <nav className="navbar pt-4 pb-4 fixed-top">
    <div className="container">
      <a className="navbar-brand">Daniel Kitanaxi</a>
      <nav className="nav">
        <a className="nav-link active" aria-current="page" href="#"> Início</a>
        <a className="nav-link" href="#">Projetos</a>
      </nav>
    </div>
  </nav>
}
