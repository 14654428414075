import React from 'react'
import { HiArrowUpRight } from 'react-icons/hi2'
import './style.scss'
export default function Footer() {
  return <footer className="footer mt-5">
  <div className="container pb-5">
    <div className="row justify-content-between">
      <div className="col-lg-4">
        <h1 className="display-3">
          Vamos trabalhar juntos
        </h1>
      </div>
      <div className="col-lg-5 d-flex align-items-end pt-4">
        <h4>
        Atualmente, estou disponível para novos projetos. Se você está em busca de um desenvolvedor Front-end dedicado e criativo, vamos conversar sobre como transformar sua próxima grande ideia em realidade!
        </h4>
      </div>
    </div>
  </div>
  <div className="footer-bottom pt-4 pb-4">
    <div className="container">
      <div className="d-flex justify-content-between align-items-center">
        <div  style={{ fontSize: "18px" }}>
          © {new Date().getFullYear()} Construído por Daniel Kitanaxi ♥️
        </div>
        <div>
          <a
            href="https://www.linkedin.com/in/daniel-kitanaxi-779b9b18b/"
            target="_blank"
            rel="noopener noreferrer"
            className="me-4"
          >
            LinkedIn <HiArrowUpRight />
          </a>
          <a
            href="https://github.com/DanielKitanaxiFilipe"
            target="_blank"
            rel="noopener noreferrer"
            className="me-4"
          >
            GitHub <HiArrowUpRight />
          </a>
          <a
            href="https://www.youtube.com/@Kitanaxi_Front-end"
            target="_blank"
            rel="noopener noreferrer"
          >
            YouTube <HiArrowUpRight />
          </a>
        </div>
      </div>
    </div>
  </div>
</footer>
}
